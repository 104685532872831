import { useCallback } from 'react';

import { EVENTS, useIdentify, useTrack } from '@arrived/analytics';
import { trackMarketingConversionEvent } from '@arrived/marketing';
import { MARKETING_CONVERSION_EVENTS } from '@arrived/models';

import { HandleEmailSubscriptionArgs } from './start';

export function useHandleEmailSubscription() {
  const identify = useIdentify();
  const track = useTrack();

  return useCallback(
    ({ position, email }: HandleEmailSubscriptionArgs) => {
      identify({
        email,
        user_setup_stage: 'lead',
        lead_created_at: new Date().toISOString(),
      });
      track(EVENTS.EMAIL_SUBSCRIPTION, { position });
      track(EVENTS.LEAD_FORM_COMPLETED, {
        email,
        lead_form_type: 'App - Start Page',
        referral_source: document.referrer,
        url: window.location.href,
      });
      trackMarketingConversionEvent({ email, type: MARKETING_CONVERSION_EVENTS.EMAIL_LEAD });
    },
    [identify, track],
  );
}
