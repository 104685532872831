import { PropsWithChildren, useCallback } from 'react';
import { PixelRatio } from 'react-native';

import { useRouter } from 'next/router';

import { FormattedMessage, useIntl } from 'react-intl';

import { BodyText, Image, LinearGradient, Stack, TitleText, gradients, tokens } from '@arrived/bricks';
import { getImageKitSource } from '@arrived/bricks-common';
import { Constants, HTMLHeadConstants, ROUTES, SessionStorageKeys } from '@arrived/common';
import { DisclaimerAccordion } from '@arrived/components';
import { TestimonialVideoCarousel } from '@arrived/features-landing-pages';
import { cdnToImageUrl, ikClient } from '@arrived/imagekit';
import { SEO } from '@arrived/router';

import { ArrivedComparedToOtherAssets } from '../conversion';
import { EmailSignup } from './EmailSignup';

export const articles = [
  {
    imgSrc: `${Constants.awsCdnUrl}/images/article_logos/bloomberg.png`,
    text: '"Arrived tries to democratize a corner of real estate investing that has been the domain of affluent individuals"',
  },
  {
    imgSrc: `${Constants.awsCdnUrl}/images/article_logos/cnbc_logo_bw.png`,
    text: '"Arrived is Democratizing access to Real Estate Investing"',
  },
  {
    imgSrc: `${Constants.awsCdnUrl}/images/article_logos/geekwire_logo_bw.png`,
    text: '"Arrived allows anyone to purchase shares of rental properties and earn a passive income while the company handles everything"',
  },
];

export interface HandleEmailSubscriptionArgs {
  email: string;
  position: string;
}

export const StartPageVersion = {
  TESTIMONIAL: 'testimonial',
  ASSET_COMPARISON: 'asset_comparison',
  BENZINGA_COBRAND: 'benzinga_cobrand',
  MONEYWISE_COBRAND: 'moneywise_cobrand',
} as const;

export type StartPageVersion = (typeof StartPageVersion)[keyof typeof StartPageVersion];

export interface StartPageProps {
  handleEmailSubscription: (subscriptionArgs: HandleEmailSubscriptionArgs) => void;
  version?: StartPageVersion;
}

const BENZINGA_LOGO_SRC = getImageKitSource({
  uri: '/images/landing/benzinga-logo.png',
  width: 150,
});

const MONEYWISE_LOGO_SRC = getImageKitSource({
  uri: '/images/landing/moneywise-logo.png',
  width: 171,
});

const ARRIVED_LOGO_SRC = getImageKitSource({
  uri: '/logos/arrived-logo-text-2023.png',
  width: 150,
});

const ARRIVED_BG_SRC = getImageKitSource({
  uri: '/images/landing/arrived-bg.png',
  width: 500,
});

export const StartPage = ({
  children,
  handleEmailSubscription,
  version = StartPageVersion.TESTIMONIAL,
}: PropsWithChildren<StartPageProps>) => {
  const router = useRouter();
  const intl = useIntl();

  const handleOnSubscribe = useCallback(
    (email: string) => {
      handleEmailSubscription({ email, position: 'header' });

      sessionStorage.setItem(SessionStorageKeys.LANDING_PAGE_EMAIL, email);

      router.push(ROUTES.public.properties.base);
    },
    [handleEmailSubscription],
  );

  const MainContent = useCallback(() => {
    switch (version) {
      case StartPageVersion.TESTIMONIAL:
        return (
          <TestimonialVideoCarousel
            alignSelf="center"
            testimonials={[
              {
                since: 'December 2021',
                quote:
                  "I invest in Arrived because it's got an exceptional leadership team that has run successful businesses before, and I think when you're looking for an early stage startup, you want to really have confidence in the team.",
                name: 'Scott',
                youtubeId: 'nbnIxz95qgc',
              },
            ]}
            maxWidth={1080}
          />
        );
      default:
        return (
          <ArrivedComparedToOtherAssets
            gap="$4"
            mx="$4"
            $gtXs={{ gap: '$6', row: true, alignItems: 'center', maxWidth: 1080, mx: '$4' }}
            $gtMd={{ gap: '$10' }}
          />
        );
    }
  }, [version]);

  const BenzingaArrivedLogo = (
    <Stack row gap="$8" mb="$6" alignItems="center" justifyContent="center" $gtXs={{ justifyContent: 'flex-start' }}>
      <Stack>
        <Image width={150} height={20} contentFit="contain" source={BENZINGA_LOGO_SRC} responsivePolicy="static" />
      </Stack>
      <Stack>
        <Image height={27} width={129} contentFit="contain" source={ARRIVED_LOGO_SRC} responsivePolicy="static" />
      </Stack>
    </Stack>
  );

  const MoneywiseArrivedLogo = (
    <Stack row gap="$8" mb="$6" alignItems="center" justifyContent="center" $gtXs={{ justifyContent: 'flex-start' }}>
      <Stack>
        <Image width={171} height={20} contentFit="contain" source={MONEYWISE_LOGO_SRC} responsivePolicy="static" />
      </Stack>
      <Stack>
        <Image height={27} width={129} contentFit="contain" source={ARRIVED_LOGO_SRC} responsivePolicy="static" />
      </Stack>
    </Stack>
  );

  return (
    <>
      <SEO>
        <SEO.Title content={HTMLHeadConstants.title} excludeTrailingTitle />
        <SEO.Description content={HTMLHeadConstants.description} />
        <SEO.Keywords content={HTMLHeadConstants.keywords} />
        <SEO.Image content={HTMLHeadConstants.imageUrl} />
      </SEO>

      <Stack gap="$8" alignItems="center" position="relative" overflow="hidden">
        <Stack width="100%" alignItems="center" $gtXs={{ gap: '$12' }}>
          <Stack
            display="none"
            position="absolute"
            top={-48}
            right={0}
            height={500}
            width={500}
            $gtSm={{ display: 'block' }}
          >
            <Image width="100%" height={500} contentFit="contain" source={ARRIVED_BG_SRC} />
          </Stack>
          <LinearGradient
            colors={gradients['gradient.primary.decorative']}
            start={[1, 1]}
            end={[0, 0]}
            locations={[0.1823, 0.9, 1]}
            position="absolute"
            width="100%"
            height="100%"
          />
          <Stack gap="$8" $gtXs={{ gap: '$12', py: '$12' }} width="100%" maxWidth={1080} px="$4" my="$12">
            <Stack alignItems="center" position="relative" width="100%">
              <Stack
                width="100%"
                gap="$8"
                $gtXs={{ alignItems: 'center', row: true, justifyContent: 'space-between', gap: '$12', pb: '$6' }}
              >
                <Stack $gtXs={{ flexGrow: 0, flexBasis: 400 }} gap="$6">
                  {version === StartPageVersion.BENZINGA_COBRAND && BenzingaArrivedLogo}
                  {version === StartPageVersion.MONEYWISE_COBRAND && MoneywiseArrivedLogo}
                  {version !== StartPageVersion.BENZINGA_COBRAND && version !== StartPageVersion.MONEYWISE_COBRAND && (
                    <Stack alignItems="flex-start" height={50}>
                      <Image
                        height={30}
                        width={150}
                        contentFit="contain"
                        source={ARRIVED_LOGO_SRC}
                        responsivePolicy="static"
                      />
                    </Stack>
                  )}
                  <TitleText token="title.display.medium">
                    <FormattedMessage id="common.grow-your-portfolio" />
                  </TitleText>
                  <BodyText token="body.default.large">
                    <FormattedMessage id="landing.subheader" />
                  </BodyText>
                  <EmailSignup gap="$2" onSubmit={handleOnSubscribe}>
                    <EmailSignup.Input
                      inputProps={{
                        frameProps: { bg: '$onSurface.neutral.zebraAlt', boc: '$onSurface.neutral.muted' },
                        placeholderTextColor: tokens.color['neutral.light.500'].val,
                        placeholder: intl.formatMessage({ id: 'common.email.placeholder' }),
                      }}
                    />
                    <EmailSignup.Button />
                  </EmailSignup>
                </Stack>
                <Stack flex={1} $gtXs={{ flex: 0, flexBasis: 400 }} centered>
                  {children}
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
        <MainContent />
        <Stack
          bg="$onSurface.neutral.zebraAlt"
          width="100%"
          gap="$12"
          py="$8"
          px="$6"
          centered
          $gtXs={{ centered: false, row: true, alignItems: 'flex-start' }}
        >
          {articles.map(({ imgSrc, text }, idx) => (
            <Stack centered key={idx} maxWidth={350} $gtXs={{ maxWidth: 300 }} gap="$4">
              <Image
                height={22}
                width={200}
                contentFit="contain"
                source={ikClient.url({
                  path: cdnToImageUrl(imgSrc),
                  transformation: [{ height: PixelRatio.getPixelSizeForLayoutSize(22).toString() }],
                })}
              />
              <BodyText textAlign="center" token="body.compact.small" color="$onSurface.neutral.muted">
                {text}
              </BodyText>
            </Stack>
          ))}
        </Stack>
      </Stack>
      <DisclaimerAccordion />
    </>
  );
};

StartPage.hasNav = false;
