import { useCallback } from 'react';

import { EVENTS, useTrack } from '@arrived/analytics';
import { DataPoint, ProductCard } from '@arrived/bricks';
import { ikClient } from '@arrived/imagekit';

import { HandleEmailSubscriptionArgs, StartPage } from './start';
import { useHandleEmailSubscription } from './useHandleEmailSubscription';

const imageSrc = ikClient.url({
  path: '/byers-house/byers-house-placeholder.jpg',
  transformation: [{ width: '800px' }],
});

export const StartPageByersHouse = () => {
  const track = useTrack();

  const handleEmailSubscriptionBase = useHandleEmailSubscription();

  const handleEmailSubscription = useCallback(
    ({ position, email }: HandleEmailSubscriptionArgs) => {
      handleEmailSubscriptionBase({ position, email });
      track(EVENTS.BYERS_HOUSE_INTEREST, { email });
    },
    [handleEmailSubscriptionBase, track],
  );

  return (
    <StartPage handleEmailSubscription={handleEmailSubscription}>
      <ProductCard
        maxWidth={400}
        width="100%"
        aspectRatio={1}
        flexGrow={1}
        cursor="default"
        condensed
        $gtXs={{ condensed: false }}
      >
        <ProductCard.Image source={imageSrc} />
        <ProductCard.Title>The Byers House</ProductCard.Title>
        <ProductCard.Description>
          <ProductCard.Description.Text>
            Invest in the iconic home featured in the hit show Stranger Things
          </ProductCard.Description.Text>
        </ProductCard.Description>
        <ProductCard.Content>
          <DataPoint variant="minimized" inline>
            <DataPoint.Label>Fayetteville, GA</DataPoint.Label>
          </DataPoint>
        </ProductCard.Content>
      </ProductCard>
    </StartPage>
  );
};

StartPageByersHouse.hasNav = false;
