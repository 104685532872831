import { useRouter } from 'next/router';

import { Image } from '@arrived/bricks';
import { getImageKitSource } from '@arrived/bricks-common';
import { useFeature } from '@arrived/feature-flags';

import { StartPage, StartPageVersion } from './start';
import { useHandleEmailSubscription } from './useHandleEmailSubscription';

const source = getImageKitSource({
  uri: '/images/landing/the-oscar.png',
  width: 400,
});

export const StartPageBenzinga = () => {
  const router = useRouter();
  const abTestSearchParam = router.query?.['abptff'] as string;

  useFeature(abTestSearchParam);

  const handleEmailSubscription = useHandleEmailSubscription();

  return (
    <StartPage handleEmailSubscription={handleEmailSubscription} version={StartPageVersion.BENZINGA_COBRAND}>
      <Image
        aspectRatio={1}
        maxWidth={400}
        width="100%"
        flex={1}
        contentFit="contain"
        source={source}
        responsivePolicy="static"
      />
    </StartPage>
  );
};

StartPageBenzinga.hasNav = false;
