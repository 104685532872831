import { useCallback, useMemo } from 'react';
import { PixelRatio } from 'react-native';

import { GetStaticProps } from 'next';
import { useRouter } from 'next/router';

import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';

import { BodyText, DataPoint, Image, ProductCard, Stack, TitleText, tokens } from '@arrived/bricks';
import { Constants, HTMLHeadConstants, ROUTES, SessionStorageKeys } from '@arrived/common';
import { DisclaimerAccordion } from '@arrived/components';
import { TestimonialVideoCarousel } from '@arrived/features-landing-pages';
import { cdnToImageUrl, ikClient } from '@arrived/imagekit';
import { Offering } from '@arrived/models';
import { SEO } from '@arrived/router';

import { EmailSignup } from './EmailSignup';
import { articles } from './start';
import { useHandleEmailSubscription } from './useHandleEmailSubscription';

export interface StartPagePCFProps {
  offering: Pick<Offering, 'shortName' | 'name'> & { loanCount: number };
  photo: string;
}

export const getStaticProps: GetStaticProps<StartPagePCFProps> = async () => {
  // Ideally we'd pull the actual data for the Offering from abacus, however, there's not a clear
  // way to know what environment we're using as the back-end form our environment variables. For
  // example when using `.env.dev-prod` the environment variables actually use 'development' for
  // both BUILD_ENV and NODE_ENV so if I hard-coded the id for a staging offering here, the SSR
  // for this page would fail in that environment.
  return {
    props: {
      offering: { shortName: 'the-private-credit-fund', name: 'Private Credit Fund', loanCount: 5 },
      photo: `https://images.arrived.com/offerings/offr_rXg1GShpio2bVxjs78drNX/5d53a0d2e91e73ad4d089aad3c99d2e0.png`,
    },
  };
};

export const StartPagePCF = ({ offering, photo }: StartPagePCFProps) => {
  const handleEmailSubscriptionBase = useHandleEmailSubscription();
  const intl = useIntl();

  const router = useRouter();

  const handleOnSubscribe = useCallback(
    (email: string) => {
      handleEmailSubscriptionBase({ email, position: 'header' });

      sessionStorage.setItem(SessionStorageKeys.LANDING_PAGE_EMAIL, email);

      router.push({
        pathname: ROUTES.public.properties.propertyDetails.base,
        query: { shortName: offering.shortName },
      });
    },
    [offering.shortName, router],
  );

  const imageSrc = useMemo(
    () => ikClient.url({ path: cdnToImageUrl(photo), transformation: [{ width: '800px' }] }),
    [photo],
  );

  return (
    <>
      <SEO>
        <SEO.Title content={HTMLHeadConstants.title} excludeTrailingTitle />
        <SEO.Description content={HTMLHeadConstants.description} />
        <SEO.Keywords content={HTMLHeadConstants.keywords} />
        <SEO.Image content={HTMLHeadConstants.imageUrl} />
      </SEO>

      <Stack
        pt="$8"
        gap="$8"
        $gtXs={{ pt: '$12', gap: '$12' }}
        alignItems="center"
        position="relative"
        overflow="hidden"
      >
        <Stack
          position="absolute"
          width={800}
          height={800}
          bg="$onSurface.primary.decorativeAlt"
          opacity={0.15}
          transform={[{ translateX: 400 }, { translateY: -600 }, { rotate: '45deg' }]}
          $gtXs={{ transform: [{ translateX: 350 }, { translateY: -550 }, { rotate: '45deg' }] }}
        />
        <Stack px="$6" gap="$8" $gtXs={{ gap: '$12' }}>
          <Stack alignItems="center" maxWidth={1080} width="100%" mx="auto" $gtXs={{ alignItems: 'flex-start' }}>
            <Image
              width={180}
              height={39}
              $gtXs={{ width: 250, height: 54 }}
              contentFit="contain"
              source={ikClient.url({
                path: cdnToImageUrl(`${Constants.awsCdnUrl}/logos/logo_arrived.png`),
                transformation: [{ width: PixelRatio.getPixelSizeForLayoutSize(250).toString() }],
              })}
            />
          </Stack>
          <Stack alignItems="center" position="relative" width="100%">
            <Stack
              maxWidth={1080}
              width="100%"
              gap="$8"
              $gtXs={{ alignItems: 'center', row: true, justifyContent: 'space-between', gap: '$12' }}
            >
              <Stack $gtXs={{ flex: 1 }} gap="$8">
                <TitleText token="title.display.medium" $gtXs={{ token: 'title.display.large' }}>
                  <FormattedMessage id="landing.pcf.header" />
                </TitleText>
                <BodyText token="body.default.large" color="$onSurface.neutral.muted">
                  <FormattedMessage id="landing.pcf.subheader" />
                </BodyText>
                <EmailSignup gap="$2" onSubmit={handleOnSubscribe}>
                  <EmailSignup.Input
                    inputProps={{
                      frameProps: { bg: '$onSurface.neutral.zebraAlt', boc: '$onSurface.neutral.muted' },
                      placeholderTextColor: tokens.color['neutral.light.500'].val,
                      placeholder: intl.formatMessage({ id: 'common.email.placeholder' }),
                    }}
                  />
                  <EmailSignup.Button />
                </EmailSignup>
              </Stack>
              <Stack $gtXs={{ flex: 1 }} centered width="100%">
                <ProductCard
                  maxWidth={350}
                  width="100%"
                  aspectRatio={1}
                  flexGrow={1}
                  cursor="default"
                  condensed
                  $gtXs={{ condensed: false }}
                >
                  <ProductCard.Image source={imageSrc} />
                  <ProductCard.Title>{offering.name}</ProductCard.Title>
                  <ProductCard.Description>
                    <ProductCard.Description.Text>
                      <FormattedMessage id="invest.private-credit-fund" />
                    </ProductCard.Description.Text>
                  </ProductCard.Description>
                  <ProductCard.Content>
                    <DataPoint variant="minimized" inline>
                      <DataPoint.Value color="$onSurface.neutral.defaultInverted">
                        <FormattedNumber compactDisplay="short" notation="compact" value={offering.loanCount} />
                      </DataPoint.Value>
                      <DataPoint.Label color="$onSurface.neutral.defaultInverted">
                        <FormattedMessage id="invest.units.loans" />
                      </DataPoint.Label>
                    </DataPoint>
                  </ProductCard.Content>
                </ProductCard>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
        <TestimonialVideoCarousel
          alignSelf="center"
          testimonials={[
            {
              since: 'December 2021',
              quote:
                "I invest in Arrived because it's got an exceptional leadership team that has run successful businesses before, and I think when you're looking for an early stage startup, you want to really have confidence in the team.",
              name: 'Scott',
              youtubeId: 'nbnIxz95qgc',
            },
          ]}
          maxWidth={1280}
        />
        <Stack
          bg="$onSurface.neutral.zebraAlt"
          width="100%"
          gap="$12"
          py="$8"
          px="$6"
          centered
          $gtXs={{ centered: false, row: true, alignItems: 'flex-start' }}
        >
          {articles.map(({ imgSrc, text }, idx) => (
            <Stack centered key={idx} maxWidth={350} $gtXs={{ maxWidth: 300 }} gap="$4">
              <Image
                height={22}
                width={200}
                contentFit="contain"
                source={ikClient.url({
                  path: cdnToImageUrl(imgSrc),
                  transformation: [{ height: PixelRatio.getPixelSizeForLayoutSize(22).toString() }],
                })}
              />
              <BodyText textAlign="center" token="body.compact.small" color="$onSurface.neutral.muted">
                {text}
              </BodyText>
            </Stack>
          ))}
        </Stack>
      </Stack>
      <DisclaimerAccordion />
    </>
  );
};

StartPagePCF.hasNav = false;
